@tailwind base;
@tailwind components;
@tailwind utilities;
@import 'react-toastify/dist/ReactToastify.css';

@layer components {
  .rect-input {
    @apply rounded-full text-center px-3 border-2 col-span-5 font-medium;
  }

  .modal-button {
    @apply inline-flex justify-center px-4 py-2 text-sm font-medium text-blue-900 bg-blue-100 border border-transparent rounded-md hover:bg-blue-200 focus:outline-none focus-visible:ring-2 focus-visible:ring-offset-2 focus-visible:ring-blue-500;
  }

  .appearance-arrow-none::-webkit-outer-spin-button,
  .appearance-arrow-none::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }

  /* Firefox */
  .appearance-arrow-none[type='number'] {
    -moz-appearance: textfield;
  }
}

/* width */
.custom-scrollbar::-webkit-scrollbar {
  width: 5px;
}
/* Track */
.custom-scrollbar::-webkit-scrollbar-track {
  background: gray;
  border-radius: 10px;
}
/* Handle */
.custom-scrollbar::-webkit-scrollbar-thumb {
  background: #d6d3d1;
  border-radius: 10px;
}
/* Handle on hover */
.custom-scrollbar::-webkit-scrollbar-thumb:hover {
  background: #e7e5e4;
}
